import React from "react";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import BackArrow from "./BackArrow";
import { useNavigate } from "react-router-dom";

// Assets

const Topbar = ({
  title,
  showBackButton = true,
  options,
  backButtonAction,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={getClassNames(
        "container-solutions w-full py-[0.62rem] lg:px-8",
        "flex border-b border-b-grey h-[62px]"
      )}
    >
      <div className="flex items-center gap-x-2">
        {showBackButton ? (
          <BackArrow onClick={() => backButtonAction ?? navigate(-1)} />
        ) : null}
        <h4 className="header4">{title}</h4>
        {options}
      </div>
      {children}
    </div>
  );
};

export default Topbar;
