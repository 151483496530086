import React, { useState } from 'react';

// Utils
import { getClassNames } from '@utils/helpers';

// Components
import { Button } from '@components/button';
import Footer from '@components/Footer.js';

// Assets
import { ReactComponent as PlatformLogo } from '@icons/logo.svg';
import { ReactComponent as PasskeyIllustration } from '@images/passkey-illustration.svg';
import PasskeyCreateModal from './passkeyCreateModal';

const CreatePasskey = () => {
  const [showConsent, setShowConsent] = useState(false);

  // const onSubmit = async () => {
  //   setShowConsent(true);
  //   navigate("/");
  // };

  return (
    <div className='h-screen overflow-auto'>
      <PasskeyCreateModal {...{ showConsent, setShowConsent }} />
      <div
        className={getClassNames(
          'flex flex-col h-screen max-w-[1440px] mx-auto',
          showConsent && 'blur-sm',
        )}
      >
        <div className={getClassNames('')}>
          <div className='container-auth min-h-[52px] py-4 px-10 flex items-center justify-between'>
            <PlatformLogo className='' />
            {/* <Button title="Sign up" /> */}
          </div>
        </div>
        <div
          className={getClassNames(
            'h-full w-full py-16 mx-auto container-auth',
            'flex-1 flex flex-col h-full rounded items-center',
          )}
        >
          <div
            className={getClassNames(
              'w-full max-w-[832px] mx-auto flex-1 mb-10',
              'flex flex-col items-center',
            )}
          >
            <div className='max-w-[378px]'>
              <h3 className='text-[22px] leading-0'>Create a Passkey</h3>
              <p className='mt-2 mb-8 text-grey-content-secondary'>
                Passkeys enable you to access your wallet securely and easily
              </p>
              <div className='w-[378px]'>
                <div className='flex items-center py-3 px-6 gap-x-3 w-full bg-stroke rounded'>
                  <span
                    className={getClassNames(
                      'rounded-full w-[28px] h-[28px] text-xl leading-5 flex items-center justify-center',
                      'bg-grey-content-tertiary text-white2 cursor-pointer capitalize',
                    )}
                  >
                    {localStorage.getItem('email')?.charAt(0)}
                  </span>
                  <span className=''>{localStorage.getItem('email')}</span>
                </div>

                <PasskeyIllustration className='mx-auto mt-16' />

                <Button
                  title='Continue'
                  color='dark'
                  size='custom'
                  className='mt-8 w-full'
                  onClick={() => setShowConsent(true)}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CreatePasskey;
