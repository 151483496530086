import React from "react";
import { getClassNames } from "@utils/helpers";

const RadioButton = ({ active }) => {
  return (
    <div
      className={getClassNames(
        "rounded-full w-6 h-6 flex items-center justify-center border border-placeholder-grey",
        "transition-all duration-500",
        active ? "bg-black" : "bg-white4"
      )}
    >
      <div
        className={getClassNames(
          "rounded-full w-[0.375rem] h-[0.375rem] bg-white4"
        )}
      />
    </div>
  );
};

export default RadioButton;
