import React, { useState, useRef, useEffect, useCallback } from "react";

// Assets
import { ReactComponent as SearchIcon } from "@icons/search.svg";
import { ReactComponent as CancelIcon } from "@icons/close-lg.svg";

const SearchInput = ({
  placeholder,
  handleChange,
  queryParam,
  className="",
}) => {
  const [query, setQuery] = useState("");
  const inputRef = useRef();
  const [startSearch, setStartSearch] = useState(false);
  const handleQueryChange = useCallback((query) => {
    handleChange(query);
    setQuery(query);
  }, [handleChange]);
  const clearSearch = useCallback(() => {
    inputRef.current.value = "";
    handleQueryChange("");
  }, [handleQueryChange]);

  useEffect(() => {
    if (queryParam?.length === 0) clearSearch();
  }, [clearSearch, queryParam]);

  return (
    <div
      className={`flex items-center border border-grey-400 px-4 gap-x-2 ${className} ${
        startSearch ? "bg-white" : "bg-white"
      }`}
    >
      <SearchIcon />
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder || "Search"}
        onFocus={() => setStartSearch(true)}
        onBlur={() => setStartSearch(false)}
        onChange={(e) => handleQueryChange(e.target.value)}
        className={`text-base border-0 outline-none focus:border-0 ring-0 w-full ${
          startSearch ? "bg-white" : "bg-white"
        }`}
      />
      {query?.length > 0 && (
        <CancelIcon className="cursor-pointer w-4 h-4" onClick={clearSearch} />
      )}
    </div>
  );
};

export default SearchInput;
