import React from "react";
import { useNavigate } from "react-router-dom";

// Utils
import { getClassNames } from "@utils/helpers";

// Components

// Assets
import { ReactComponent as RetailCoLogo } from "@icons/retail_co_logo.svg";
import { ReactComponent as ShoesIcon } from "@icons/shoes.svg";

const PayAfterDelivery = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem("shopperData");
  // if (!user) return navigate("/login");
  const firstName = JSON.parse(user)?.fullname?.split(" ")[0];
  return (
    <div className="flex flex-col h-full bg-white w-full mx-auto max-w-[695px] p-10">
      <div className={getClassNames(" pt-3")}>
        <div className="container mx-auto min-h-[52px] relative">
          <RetailCoLogo className="" />
        </div>
      </div>
      <div className="h-full overflow-auto">
        <div
          className={getClassNames(
            "h-full w-full py-16 mx-auto container-auth",
            "flex-1 flex flex-col h-full rounded items-center"
          )}
        >
          <div
            className={getClassNames(
              "w-full max-w-[832px] mx-auto flex-1 mb-10",
              "flex flex-col items-center"
            )}
          >
            <div className="flex-1 px-14 py-5 rounded-lg bg-grey-810 w-full min-h-[254px]">
              <h4 className="header4">Thanks {firstName},</h4>
              <p className="mt-4 text-base1 text-grey-content-secondary">
                Great that you chose Retail Co. We are<br></br> processing your
                order.
              </p>
            </div>

            <div className="mt-8 py-3 w-full border-b border-stroke">
              <h4 className="header4">Your Order</h4>
              <div className="flex items-center mt-7 gap-5">
                <div className="py-6 px-8 bg-stroke">
                  <ShoesIcon />
                </div>
                <div className="flex flex-col gap-4">
                  <span className="text-blacked text-2xl whitespace-nowrap">
                    Suede Chelsea Boots
                  </span>
                  <span className="text-grey-content-secondary text-lg">
                    Black, Size 7
                  </span>
                </div>
              </div>
            </div>

            <div className="flex-1 px-14 mt-20 py-5 rounded-lg bg-grey-810 w-full flex flex-col items-center gap-y-2">
              <h4 className="header4">You chose to pay after delivery</h4>
              <button
                className="w-full max-w-[404px] bg-black py-3 px-8 text-white cursor-pointer h-fit rounded-full"
                onClick={() => navigate("/pay-by-bank")}
              >
                Pay now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayAfterDelivery;
