import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";

// Assets
import { ReactComponent as RetailCoIcon } from "@icons/retail_co_logo.svg";

const PaymentAuthorization = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // const {
  //   formState: {},
  // } = useForm({
  //   mode: "onChange",
  //   reValidateMode: "onChange",
  // });

  const onApprove = () => {
    if (location?.state?.from === "authenticate") {
      navigate(location?.state?.to);
    } else {
      navigate("/order-confirmation");
    }
  };

  return (
    <div className="bg-[#4aa0b5]">
      <div className="flex flex-col h-full min-h-screen w-full mx-auto">
        <div className="h-full overflow-auto">
          <div
            className={getClassNames(
              "h-full w-full pt-40 mx-auto container-auth",
              "flex-1 flex flex-col h-full rounded items-center"
            )}
          >
            <div
              className={getClassNames(
                "w-full max-w-[832px] mx-auto flex-1 mb-10",
                "flex flex-col items-center"
              )}
            >
              <div className="min-w-[459px] p-8 bg-white border border-stroke-primary rounded-lg">
                <div className="rounded-lg">
                  <h2 className="header2 text-[#4aa0b5]">
                    Payment authorization
                  </h2>
                  <div className="mt-8">
                    <div className="h-3 w-full bg-stroke animate-pulse" />
                    <div className="h-3 mt-2 w-2/3 bg-stroke animate-pulse" />
                  </div>
                </div>

                <div className="mt-8 p-8 bg-white border border-stroke-primary rounded-lg">
                  <RetailCoIcon className="max-w-[106px]" />
                  <hr className="border-stroke-primary my-2" />
                  <div className="flex items-center justify-between">
                    <span className="text-content-tertiary text-grey-content-tertiary">
                      Amount
                    </span>
                    <span className="bg-stroke-secondary rounded-md py-2 px-3 text-blacked">
                      85 Eur
                    </span>
                  </div>
                </div>

                <div className="flex gap-x-4 justify-between items-center">
                  <Button
                    title="Decline"
                    color="light"
                    size="custom"
                    className="mt-6 flex-1 text-red-alert bg-white2 rounded-sm"
                    // onClick={() => navigate("/setup-declined")}
                  />
                  <Button
                    title="Approve"
                    color=""
                    size="custom"
                    className="mt-6 flex-1 bg-[#4aa0b5] text-white rounded-sm"
                    onClick={onApprove}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentAuthorization;
