import * as React from "react";
const CaretSm = ({ color, width, height, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "5"}
    height={height || "4"}
    viewBox={`0 0 ${width || '5'} ${height || '4'}`}
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.500814 4H4.49955C4.91109 4 5.14601 3.48727 4.89942 3.12727L2.90006 0.218182C2.70012 -0.0727277 2.30025 -0.0727277 2.10031 0.218182L0.10094 3.12727C-0.146482 3.48727 0.0892771 4 0.500814 4Z"
      fill={color || "#02BD8E"}
    />
  </svg>
);
export default CaretSm;
