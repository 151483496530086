import React from "react";
import { getClassNames } from "@utils/helpers";
import { getRiskColor } from "@utils/helpers";

const ToolTip = ({ className, info="Tool tip", risk }) => {
  return (
    <div
      className={getClassNames(
        "z-[999] bg-body-black p-2 absolute hidden group-hover:block",
        "rounded text-white",
        className
      )}
    >
      {risk && <div className={getClassNames("w-3 h-3 rounded-sm inline-block mr-2 bg-green-alert", getRiskColor(risk))} />}
      {info}
    </div>
  );
};

export default ToolTip;
