import React from 'react';
import { useNavigate } from 'react-router-dom';

// Utils
import { getClassNames, addDays } from '@utils/helpers';

// Components
import { Button } from '@components/button';

// Assets
import { ReactComponent as RetailCoLogo } from '@icons/apollo_bank.svg';

const MockBankConsentAuthorization = () => {
  const navigate = useNavigate();

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors, isValid },
  // } = useForm({
  //   mode: "onChange",
  //   reValidateMode: "onChange",
  // });

  // const onSubmit = async () => {
  //   navigate("auth-consent");
  // };

  const bullets = [
    'Name',
    'Account name, number and sort code',
    'Account balances',
    'Details of your incoming transactions',
    'Details of your outgoing transactions',
  ];

  return (
    <div className='bg-white'>
      <div className='flex flex-col h-full min-h-screen bg-[#0F30471A] w-full mx-auto'>
        <div className={getClassNames('')}>
          <div className='container mx-auto min-h-[52px] relative flex flex-col justify-center'>
            <RetailCoLogo className='' />
          </div>
        </div>
        <div className='h-full overflow-auto'>
          <div
            className={getClassNames(
              'h-full w-full py-16 mx-auto container-auth',
              'flex-1 flex flex-col h-full rounded items-center',
            )}
          >
            <div
              className={getClassNames(
                'w-full max-w-[832px] mx-auto flex-1 mb-10',
                'flex flex-col items-center',
              )}
            >
              <div className='max-w-[459px] p-8 bg-white border border-stroke-primary rounded-lg'>
                <div className='rounded-lg'>
                  <h2 className='header2 text-body-black'>
                    Consent authorization
                  </h2>
                  <p className='my-8 text-grey-content-tertiary'>
                    If approved,{' '}
                    <span className='text-grey-content-secondary'>
                      Trudenty
                    </span>{' '}
                    will have access to the information listed until your
                    permission expires on <br /> {addDays(180)}. (180 days)
                  </p>
                  <div className='rounded-lg bg-white2 border border-grey-stroke-tertiary'>
                    <ul className='relative flex flex-col list-none divide-y divide-grey-10'>
                      {bullets.map((bullet) => (
                        <li
                          key={bullet}
                          className=' py-4 px-4 lg:px-5   flex gap-x-2 items-center'
                        >
                          <div className='w-[9px] h-[9px] rounded-sm bg-stroke-secondary' />
                          <p className=' text-grey-content-secondary'>
                            {bullet}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className='flex gap-x-4 justify-between items-center'>
                  <Button
                    title='Decline'
                    color='light'
                    size='custom'
                    className='mt-6 flex-1 text-red-alert bg-white2'
                    onClick={() => navigate('/setup-declined')}
                  />
                  <Button
                    title='Approve'
                    color='dark'
                    size='custom'
                    className='mt-6 flex-1'
                    onClick={() => navigate('/setup-success')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MockBankConsentAuthorization;
