export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};

export const getClassNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const toCamelCase = (str) => {
  return str
    .replace(/\s(.)/g, function (a) {
      return a.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function (b) {
      return b.toLowerCase();
    });
};

export const getInitials = (fullName = "") => {
  return fullName
    ?.split(" ")
    .map((part) => part[0]?.toUpperCase())
    .join("");
};

export const URLPattern2 =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
export const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/;

export const URLPattern =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g;

export const getRiskColor = (risk, inactive) => {
  if (inactive) return "bg-grey-content-tertiary";
  switch (risk) {
    case "LOW_RISK_SHOPPER":
      return "bg-risk-green";
    case "trusted":
      return "bg-risk-green";
    case "MEDIUM_RISK_SHOPPER":
      return "bg-trud-orange";
    case "medium":
      return "bg-trud-orange";
    case "HIGH_RISK_SHOPPER":
      return "bg-risk-red";
    case "high":
      return "bg-risk-red";
    default:
      return "bg-grey-content-tertiary";
  }
};

export const getRiskTitle = (risk) => {
  switch (risk) {
    case "LOW_RISK_SHOPPER":
      return "Trusted Consumer";
    case "trusted":
      return "Trusted Consumer";
    case "MEDIUM_RISK_SHOPPER":
      return "Medium Risk Consumer";
    case "medium":
      return "Medium Risk Consumer";
    case "HIGH_RISK_SHOPPER":
      return "High Risk Consumer";
    case "high":
      return "High Risk Consumer";
    default:
      return "";
  }
};
export const getRiskWithScore = (score) => {
  if (score >= 80) {
    return "trusted";
  } else if (score >= 70) {
    return "medium";
  } else {
    return "high";
  }
};

export const getScoreColor = (score, inactive = false) => {
  if (inactive) {
    return "text-grey-content-tertiary";
  } else if (score >= 80) {
    return "text-score-green";
  } else if (score >= 70) {
    return "text-trud-orange";
  } else {
    return "text-red-alert";
  }
};
export const getScoreColorWithRisk = (risk) => {
  if (risk === "trusted" || risk === "LOW_RISK_SHOPPER") {
    return "text-score-green";
  } else if (risk === "medium" || risk === "MEDIUM_RISK_SHOPPER") {
    return "text-trud-orange";
  } else {
    return "text-red-alert";
  }
};

export const addDays = (days) => {
  var result = new Date();
  result.setDate(result.getDate() + days);
  return result.toDateString();
};

export const members = [
  {
    id: 0,
    firstName: "Lerato",
    lastName: "Matsio",
    email: "lerato@trudenty.com",
    lastSeen: "10 secs ago",
    role: "admin",
    active: false,
  },
  {
    id: 1,
    firstName: "Marco",
    lastName: "Kome",
    email: "marco@trudenty.com",
    lastSeen: "Yesterday at 16:30",
    role: "viewer",
    active: true,
  },
  {
    id: 2,
    firstName: "Tuttuh",
    lastName: "Adegun",
    email: "tuttuh@trudenty.com",
    lastSeen: "10 secs ago",
    role: "admin",
    active: false,
  },
  {
    id: 3,
    firstName: "Ose Yaw",
    lastName: "Ababio",
    email: "ababio@trudenty.com",
    lastSeen: "Yesterday at 16:30",
    role: "viewer",
    active: true,
  },
  {
    id: 4,
    firstName: "Amy",
    lastName: "Fowler",
    email: "amy@trudenty.com",
    lastSeen: "10 secs ago",
    role: "admin",
    active: false,
  },
  {
    id: 5,
    firstName: "Brian",
    lastName: "Goldberg",
    email: "brian@trudenty.com",
    lastSeen: "3 days ago",
    role: "viewer",
    active: true,
  },
];
