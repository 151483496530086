import React, { useContext } from "react";
import { authContext } from "@context/auth";
import { ScoreBubbleSmall } from "@pages/dashboard/components/shoppersCharts";
import { isRegisteredSelector } from "../redux/slice/registeredSlice.js";
import { ReactComponent as Notification } from "@icons/notification.svg";
import { useSelector } from "react-redux";

const TitleBar = ({ title = "" }) => {
  const isRegistered = useSelector(isRegisteredSelector);
  const { shopperData } = useContext(authContext);
  const { total_score, risk_level } = shopperData || { total_score: "_" };

  return (
    <div className="bg-nav-white/95 mb-10 pt-3">
      <div className="max-w-[1032px] mx-auto flex items-center justify-between ">
        <h3 className="paragraph-large">{title}</h3>
        <div className="flex items-center gap-4">
          <Notification />
          <ScoreBubbleSmall
            score={isRegistered ? total_score : "-"}
            risk_level={risk_level}
          />
        </div>
      </div>
    </div>
  );
};

export default TitleBar;
