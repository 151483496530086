import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";

// Assets
import { ReactComponent as BigTick } from "@icons/bigtick.svg";

const EnrollSuccess = ({ setStep }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  setTimeout(() => {
    setShow(true);
  }, 200);

  const login = () => {
    setStep(0)
    navigate("/");
  };
  return (
    <div className="h-full -mt-16 flex flex-col max-w-[372px] mx-auto items-center justify-center">
      <BigTick
        className={getClassNames(
          "transition-all duration-500",
          show ? "opacity-100 translate-y-0" : "-translate-y-20 opacity-0"
        )}
      />
      <div
        className={getClassNames(
          "transition-all duration-500 mt-16 flex flex-col items-center",
          show ? "opacity-100 translate-y-0" : "translate-y-20 opacity-0"
        )}
      >
        <h3 className="text-body-black text-xl2 text-center">
          Your registration is complete
        </h3>
        <p className="mt-4 text-desc-grey text-lg leading-xl2 text-center">
          All done! You will automatically be redirected to the log in page or
          you can click the log in button.
        </p>
        <Button
          title="Log in"
          color="dark"
          size="custom"
          className="mt-4 w-fit"
          onClick={login}
        />
      </div>
    </div>
  );
};

export default EnrollSuccess;
