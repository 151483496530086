import React from 'react'

// Assets
import { ReactComponent as Appstore } from '@icons/appstore.svg'
import { ReactComponent as Playstore } from '@icons/play-store.svg'
import downloadQr from '@images/downloadqr.png'

const DownloadLinks = () => {
  return (
    <div className='w-full mt-6 bg-white border-stroke rounded-2xl flex divide-x divide-stroke'>
        <div className="p-6">
            <h3 className="text-lg1">Download the Trudenty app</h3>
            <div className="flex mt-2 gap-x-1">
                <Playstore />
                <Appstore />
            </div>
        </div>
        <div className="flex w-full items-center justify-center">
            <img src={downloadQr} className='max-w-[4rem]' alt="qrcode" />
        </div>
    </div>
  )
}

export default DownloadLinks