import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { authContext } from "@context/auth";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import RadioButton from "@components/RadioButton";
import Sidebar from "./sidebar";
import ToolTip from "@components/ToolTip";

// Assets
import { ReactComponent as ShoesIcon } from "@icons/shoes.svg";
import { ReactComponent as BackIcon } from "@icons/back-arrow.svg";
import { ReactComponent as BankIcon } from "@icons/bank.svg";
import { ReactComponent as AppleIcon } from "@icons/apple.svg";
import { ReactComponent as GoogleIcon } from "@icons/google-square.svg";
import { ReactComponent as DebitCardIcon } from "@icons/credit-card.svg";
import { ReactComponent as PayDeliveryIcon } from "@icons/pay_delivery.svg";
import { ReactComponent as PayInstallmentIcon } from "@icons/pay_installment.svg";
import TitleBar from "@components/TitleBar";

const Shop = () => {
  const navigate = useNavigate();
  const { addHistory, shopperData } = useContext(authContext);
  const [paymentMethod, setPaymentMethod] = useState(
    shopperData?.risk_level === "LOW_RISK_SHOPPER" ? "after" : "bank"
  );
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const handleSubmit = (method) => {
    switch (method) {
      case "after":
        navigate("/pay-after-delivery");
        break;
      case "bank":
        navigate("/pay-by-bank");
        break;
      default:
        navigate("/pay-by-bank");
    }
  };
  // const user = localStorage.getItem("user");
  // if (!user) return navigate("/login");
  return (
    <div className="bg-grey h-screen overflow-auto min-w-[1248px]">
      <div className="h-full mx-auto">
        <div className="h-full grid grid-cols-[303px,1fr] gap-2">
          <Sidebar />

          <div className="bg-white">
            <TitleBar title="Bag" />
            <div className="max-w-[1032px] mx-auto">
              <div className="flex items-center py-3 gap-[17px] border-b border-stroke">
                <div className="py-6 px-8 bg-stroke">
                  <ShoesIcon />
                </div>
                <div className="flex justify-between w-full">
                  <div className="flex flex-col gap-4">
                    <span className="text-blacked text-2xl whitespace-nowrap">
                      Suede Chelsea Boots
                    </span>
                    <span className="text-grey-content-secondary text-lg">
                      Black, Size 7
                    </span>
                  </div>

                  <div className="flex gap-8 items-center justify-around ">
                    <div className="flex gap-2 bg-stroke items-center justify-around px-3 py-2">
                      <span className="pr-2">1</span>
                      <BackIcon className="-rotate-90  cursor-pointer" />
                    </div>
                    <span>€85</span>
                  </div>
                </div>
              </div>
              {showPaymentOptions && (
                <div className="mt-8">
                  <p className="text-sm text-grey-content-secondary mx-auto max-w-[571px] ">
                    Select payment method
                  </p>
                  <div className="mt-4 flex flex-col gap-y-4  max-w-[571px] mx-auto">
                    {shopperData?.available_payment_methods &&
                    shopperData?.available_payment_methods[
                      "Pay after delivery"
                    ] === true ? (
                      <div
                        className={getClassNames(
                          "flex items-center justify-between cursor-pointer "
                        )}
                        onClick={() => setPaymentMethod("after")}
                      >
                        <div className="flex items-center gap-x-2 w-90">
                          <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                            <PayDeliveryIcon />
                          </div>
                          <span className="text-sm text-body-black">
                            Pay after delivery
                          </span>
                        </div>
                        <RadioButton active={paymentMethod === "after"} />
                      </div>
                    ) : null}
                    {shopperData?.available_payment_methods &&
                    shopperData?.available_payment_methods[
                      "Pay by installments"
                    ] === true ? (
                      <div
                        className={getClassNames(
                          "flex items-center justify-between cursor-pointer opacity-50"
                        )}
                      >
                        <div className="flex items-center gap-x-2">
                          <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                            <PayInstallmentIcon />
                          </div>
                          <span className="text-sm text-body-black">
                            Pay by installments
                          </span>
                        </div>
                        <RadioButton active={paymentMethod === "installment"} />
                      </div>
                    ) : null}

                    <div
                      className="flex items-center justify-between cursor-pointer opacity-50"
                      // onClick={() => setPaymentMethod("card")}
                    >
                      <div className="flex items-center gap-x-2">
                        <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                          <DebitCardIcon />
                        </div>
                        <span className="text-sm text-body-black">
                          Debit/Credit card
                        </span>
                      </div>
                      <RadioButton active={paymentMethod === "card"} />
                    </div>
                    {shopperData?.available_payment_methods &&
                      shopperData?.available_payment_methods["Pay by Bank"] ===
                        true && (
                        <div
                          className="flex items-center justify-between cursor-pointer"
                          onClick={() => setPaymentMethod("bank")}
                        >
                          <div className="flex items-center gap-x-2">
                            <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                              <BankIcon />
                            </div>
                            <span className="text-sm text-body-black">
                              Pay by Bank
                            </span>
                          </div>
                          <RadioButton active={paymentMethod === "bank"} />
                        </div>
                      )}
                    <div
                      className="flex items-center justify-between cursor-pointer opacity-50"
                      // onClick={() => setPaymentMethod("apple")}
                    >
                      <div className="flex items-center gap-x-2">
                        <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                          <AppleIcon />
                        </div>
                        <span className="text-sm text-body-black">
                          Apple Pay
                        </span>
                      </div>
                      <RadioButton active={paymentMethod === "apple"} />
                    </div>
                    <div
                      className="flex items-center justify-between cursor-pointer opacity-50"
                      // onClick={() => setPaymentMethod("google")}
                    >
                      <div className="flex items-center gap-x-2">
                        <div className="bg-stroke-secondary py-1 flex justify-center min-w-[43px]">
                          <GoogleIcon />
                        </div>
                        <span className="text-sm text-body-black">
                          Google Pay
                        </span>
                      </div>
                      <RadioButton active={paymentMethod === "google"} />
                    </div>
                  </div>
                  <div className="relative group w-full max-w-[404px] mx-auto mt-9">
                    <button
                      className="w-full max-w-[404px] bg-black py-3 px-8 rounded-full text-white cursor-pointer h-fit"
                      onClick={() => {
                        addHistory(
                          "Checkout - Consumer Trust Index credential has been read"
                        );
                        // navigate("/dashboard/1/wallet");
                        handleSubmit(paymentMethod);
                      }}
                    >
                      Checkout
                    </button>
                  </div>
                </div>
              )}
              {!showPaymentOptions && (
                <div className="relative group w-full max-w-[404px] mx-auto mt-9">
                  <button
                    className="w-full max-w-[404px] bg-black py-3 px-8 text-white cursor-pointer h-fit rounded-full"
                    onClick={() => setShowPaymentOptions(true)}
                  >
                    Checkout
                  </button>
                  <ToolTip
                    className="max-w-[283px] top-14 left-0 right-0 mx-auto"
                    info="You have given consent to Retail Co to read your Consumer Trust Index for the next 180 days. "
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
