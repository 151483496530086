import React, { useState } from "react";
import { Tab } from "@headlessui/react";

// utils
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import CreateWalletModal from "./components/createWalletModal";
import History from "./components/history";
import ShopperDetails from "./components/shopperDetails";
import Topbar from "@components/topbar";

const Dashboard = () => {
  // const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  // const [loading, setLoading] = useState(false);
  // const { shopperData, setShopperData } = useContext(authContext);
  const shopperData = JSON.parse(localStorage.getItem("shopperData"));

  // useEffect(() => {
  //   // if (!user) return navigate("/login");
  //   if(!shopperData) {
  //     navigate("/bank-login");
  //   }
  // }, [shopperData])

  // const onSave = async () => {
  //   setLoading(true);
  //   await axios
  //     .get("https://apiiec.trudenty.com/api/trusted_shoppers", {
  //       headers: {
  //         "return-complete-shopper-list": "TRUE",
  //       },
  //     })
  //     .then((response) => {
  //       setLoading(false);
  //       navigate("/dashboard/1/wallet");
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       console.log(error);
  //     });
  // };

  const tabs = ["Report", "History"];

  return (
    <div className="h-screen overflow-hidden">
      <div className="container-solutions flex w-full flex-col h-full pb-[45px] bg-white">
        <Topbar title="View Consumer Trust Index" />
        <CreateWalletModal {...{ show, setShow }} />
        <Tab.Group>
          <div className="md:flex items-center justify-between mt-8 w-full max-w-[813px] mx-auto">
            <h4 className="header4">Consumer Trust Index</h4>
            <div className="md:ml-auto w-full md:max-w-max">
              <Tab.List className="px-0 md:pl-8 pt-4 pb-4 flex">
                {tabs.map((tab, i) => (
                  <Tab key={tab} disabled={i === 1}>
                    {({ selected }) => (
                      <div
                        className={getClassNames(
                          "px-4 py-4 border border-grey-stroke-primary leading-[0]",
                          selected
                            ? "text-trud-black bg-white"
                            : "text-grey-content-secondary bg-grey-stroke-primary",
                          i === 0 ? "rounded-l" : "rounded-r"
                        )}
                      >
                        {tab}
                      </div>
                    )}
                  </Tab>
                ))}
                <Button
                  title="Revoke"
                  category="2"
                  color="light"
                  className="h-full py-4 leading-[0] text-red-alert border border-black ml-auto md:ml-2"
                  onClick={() => {}}
                />
              </Tab.List>
            </div>
          </div>

          <div className="overflow-auto w-full h-full flex flex-col">
            <div className="flex-1  pb-10 bg-white w-full  max-w-[813px] mx-auto border border-grey-stroke-primary rounded-lg">
              <div
                className={getClassNames(
                  "relative border-b border-grey-stroke-primary flex items-center justify-between px-3 md:px-8",
                  "bg-white py-4 rounded-t-lg"
                )}
              >
                <div className="flex items-center gap-x-4">
                  <span className="whitespace-nowrap">
                    {shopperData?.fullname}
                  </span>
                  <p
                    className={getClassNames(
                      "text-desc-grey text-sm leading-base1 max-w-[380px] h-full",
                      "flex items-center bg-stroke rounded w-fit py-1 px-2 whitespace-nowrap"
                    )}
                  >
                    {shopperData?.project_identifier}
                  </p>
                </div>
              </div>
              <Tab.Panels className="h-full">
                <Tab.Panel>
                  <ShopperDetails shopper={shopperData} />
                </Tab.Panel>
                <Tab.Panel className="h-full">
                  <History {...{ setShow }} />
                </Tab.Panel>
              </Tab.Panels>
              <p className="text-right text-sm text-grey-content-quaternary">
                Powered by Trudenty
              </p>
            </div>
          </div>
        </Tab.Group>
      </div>
    </div>
  );
};

export default Dashboard;
