import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Utils
import { axiosInstance } from "@utils/baseAxios";

// Components
import Header from "@components/header";
import { RiskTag, ScoreBubbleMid } from "./dashboard/components/shoppersCharts";
import { Button } from "@components/button";
import { authContext } from "@context/auth";
import ConfigChangesPopover from "@components/configChangesPopover";
import { getInitials } from "@utils/helpers";
import { useDispatch } from "react-redux";
import { setIsRegistered } from "../redux/slice/registeredSlice";

// Assets

const TrustedMessage = {
  low_risk_message:
    "Trusted consumers show no evidence of first-party fraud and a low likelihood of fraudulent behaviour, supported by strong indicators of trustworthiness in key risk parameters.",
  medium_risk_message:
    "Medium Risk Consumers show minimal evidence of first-party fraud and low to moderate levels of fraud risk.",
  high_risk_message:
    "High Risk Consumers consistently show evidence or a high likelihood of first-party fraud, posing significant risk across the network.",
};

let TrustedUserList = [
  {
    id: 0,
    short_name: "SJ",
    full_name: "Samantha Jones",
    email: "samantha@gmail.com",
    phone_no: "+44 079 8918 4157",
  },
  {
    id: 1,
    short_name: "JW",
    full_name: "John Wall",
    email: "johnwall@gmail.com",
    phone_no: "+44 073 9421 856",
  },
  {
    id: 2,
    short_name: "BL",
    full_name: "Bruce Lawrence",
    email: "brucel2003@gmail.com",
    phone_no: "+44 075 1384 9275",
  },
];

const SelectShopper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setShopperData, setIsAuthenticated } = useContext(authContext);
  const [consumerList, setConsumerList] = useState();

  useEffect(() => {
    dispatch(setIsRegistered({ isRegistered: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getConsumerList = async () => {
      const email = localStorage.getItem("email");
      // if (!email) {
      //   await axiosInstance
      //   .delete("/clear_session")
      //   .then(() => {
      //     setIsAuthenticated(false);
      //   })
      //   .catch((err) => console.log(err));
      // };
      try {
        const res = await axiosInstance.get(
          `/trusted_shoppers/usersList?email=${email}`
        );
        if (res.status === 200) {
          setConsumerList(res.data);
        }
      } catch (error) {
        console.log("ERROR", error);
      }
    };
    getConsumerList();
  }, [setIsAuthenticated]);

  // useEffect(() => {
  //   if(consumerList?.length) {
  //     consumerList.map((consumer) => {
  //       const findIndex = TrustedUserList.findIndex(
  //         (users) => users.id == consumer.id
  //       );
  //       TrustedUserList[findIndex] = {
  //         ...consumer,
  //         ...TrustedUserList[findIndex],
  //       };
  //     });
  //   } else {
  //     getConsumerList();
  //   }
  // }, [consumerList]);

  const getShopperDetails = async (id, email, phone_no) => {
    localStorage.setItem("email", email);
    localStorage.setItem("phone_no", phone_no);
    await axiosInstance
      .get(`/trusted_shoppers/${id}?demo_type=WORDLINE`)
      .then(async (response) => {
        await localStorage.setItem(
          "shopperData",
          JSON.stringify(response?.data)
        );
        setShopperData(response?.data);
        navigate("/dashboard/1");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="gradient-3 relative">
      <ConfigChangesPopover />
      <div className="w-full h-full absolute inset-0 bg-person-consumer bg-cover bg-right-center bg-no-repeat blur-[17px]"></div>
      <div className="h-full w-full relative z-10 bg-[linear-gradient(180deg,#EBEBEB_0%,rgba(217,217,217,0.00)_24.32%)]">
        <div className="relative mx-auto h-screen flex flex-col">
          <Header title="Consumer Demo" />
          <div className="container-centered mx-auto">
            <h1 className=" text-[2.5rem] mt-16 mb-6">
              Select a consumer profile
            </h1>
            <div className="flex gap-6">
              {consumerList?.map((user) => (
                <div className="bg-white group rounded-2xl w-full flex flex-col relative overflow-hidden">
                  <div className="border-header px-10 py-3 bg-grey-10/[77] flex items-center justify-between">
                    <div className="flex items-center gap-x-3">
                      <div className="flex rounded-full min-w-[40px] min-h-[40px] items-center justify-center bg-blacked text-white header6">
                        {getInitials(user?.fullname)}
                      </div>
                      <span className="text-grey-content-secondary header4">
                        {user?.fullname}
                      </span>
                    </div>
                    <ScoreBubbleMid
                      score={user?.total_score}
                      risk={user?.risk_level}
                    />
                  </div>
                  <div className="p-10 flex-1 flex flex-col items-start">
                    <RiskTag risk={user?.risk_level} />
                    <p className="mt-6 paragraph-large flex-1 text-grey-content-secondary z-10 backdrop-blur-[1px]">
                      {user?.risk_level === "LOW_RISK_SHOPPER"
                        ? TrustedMessage.low_risk_message
                        : user?.risk_level === "MEDIUM_RISK_SHOPPER"
                        ? TrustedMessage.medium_risk_message
                        : TrustedMessage.high_risk_message}
                    </p>
                  </div>
                  <div className="border-t border-stroke group">
                    <div className="px-6 py-10">
                      <Button
                        title="Select experience"
                        color=""
                        onClick={() => {
                          getShopperDetails(
                            user?.id,
                            TrustedUserList[user?.id]?.email,
                            TrustedUserList[user?.id]?.phone_no
                          );
                        }}
                        className="w-full py-3 text-xl bg-stroke group-hover:bg-black group-hover:text-white"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectShopper;
