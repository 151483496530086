import { Fragment, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";

// Helpers
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";

// Assets
import { ReactComponent as ConsentIllustration } from "@images/consent_for_open_banking.svg";

const ConsentModal = ({ showConsent, setShowConsent }) => {
  const addDays = (days) => {
    var result = new Date();
    result.setDate(result.getDate() + days);
    return result.toDateString();
  };
  return (
    <Transition appear show={showConsent} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={getClassNames(
                  `w-full md:max-w-[464px] transform rounded-md bg-white text-left align-middle 
                shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all p-6 lg:p-10 mx-3`
                )}
              >
                <ConsentIllustration className="max-w-full" />
                <h3 className="header3 text-body-black mt-6">
                  Consents for Open Banking
                </h3>
                <p className="paragraph1 mt-3 text-grey-content-tertiary">
                  If approved,{" "}
                  <span className="text-grey-content-secondary">Trudenty</span>{" "}
                  will have access to your Consumer Trust Index history and Open
                  Banking until your permission expires on{" "}
                  <span>{addDays(180)}</span> (180 days), or until you revoke
                  your consent.
                </p>
                <Button
                  title="Got it"
                  category="1"
                  color="dark"
                  className={getClassNames("mt-10 w-full")}
                  onClick={() => setShowConsent(false)}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConsentModal;
