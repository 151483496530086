import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import { useDispatch } from "react-redux";
import { setIsRegistered } from "../../redux/slice/registeredSlice.js";

const ConsentAuthorization = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const onApprove = () => {
    if (location?.state?.from === "authenticate") {
      dispatch(setIsRegistered({ isRegistered: true }));
      navigate(location?.state?.to);
    } else {
      navigate("/order-confirmation");
    }
  };

  return (
    <div className="bg-[#4aa0b5]">
      <div className="flex flex-col h-full min-h-screen w-full mx-auto">
        <div className="h-full overflow-auto">
          <div
            className={getClassNames(
              "h-full w-full pt-40 mx-auto container-auth",
              "flex-1 flex flex-col h-full rounded items-center"
            )}
          >
            <div
              className={getClassNames(
                "w-full max-w-[832px] mx-auto flex-1 mb-10",
                "flex flex-col items-center"
              )}
            >
              <div className="min-w-[459px] min-h-[300px] h-full p-8 bg-white border border-stroke-primary rounded-lg">
                <div className="rounded-lg">
                  <h2 className="header2 text-[#4aa0b5] text-center">
                    Consent Authorization
                  </h2>
                </div>

                <div className="mt-3">
                  <p className="text-lg text-[#4aa0b5]">
                    Please give permissions for: Accounts,Balances,Transactions
                  </p>
                </div>

                <div className="flex gap-x-2 items-left">
                  <Button
                    title="Approve"
                    color=""
                    size="custom"
                    className="mt-6 bg-[#4aa0b5] text-white rounded-md py-3"
                    onClick={onApprove}
                  />
                  <Button
                    title="Deny"
                    color=""
                    size="custom"
                    className="mt-6 bg-[#4aa0b5] text-white rounded-md py-3"
                    onClick={() => navigate("/setup-declined")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConsentAuthorization;
