import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import { Button } from "@components/button";

const History = ({ setShow }) => {
  const navigate = useNavigate()
  return (
    <div className="h-full w-full flex flex-col items-center justify-center bg-white2">
      <div className="max-w-full md:max-w-[376px]">
        <h4 className="header4 text-body-black text-center">
          Create a wallet to see your history, <br className="md:hidden" /> data
          and manage consents
        </h4>
        <p className="paragraph2 mt-4 text-desc-grey text-lg leading-xl2 text-center">
          Your wallet will be set up with Trudenty.
        </p>
        <Button title="Create a Wallet" color="dark" className="mx-auto mt-8" onClick={() => navigate("/dashboard/1/wallet")} />
      </div>
    </div>
  );
};

export default History;
