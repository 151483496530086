import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// Utils
import { axiosInstance } from "@utils/baseAxios";
import { authContext } from "@context/auth";

// Assets
import { ReactComponent as CloseIcon } from "@icons/close.svg";
import { ReactComponent as MenuIcon } from "@icons/menu.svg";

export const ButtonContext = createContext();

export const ButtonContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [showControls, setShowControls] = useState(true);
  const { setIsAuthenticated } = useContext(authContext);

  const logout = async () => {
    await axiosInstance
      .delete("/clear_session")
      .then(() => {
        setIsAuthenticated(false);
      })
      .catch((err) => console.log(err));
  };

  const ControlButtons = () => {
    return (
      <div className="flex flex-col gap-3 fixed right-6 bottom-20">
        <a href="https://experience.worldlineiec.trudenty.com/menu">
          <MenuIcon
            onClick={() => navigate("/menu")}
            className="cursor-pointer"
          />
        </a>
        <a href="https://experience.worldlineiec.trudenty.com" onClick={logout}>
          <CloseIcon className="cursor-pointer" />
        </a>
      </div>
    );
  };
  return (
    <ButtonContext.Provider value={{ showControls, setShowControls }}>
      {children}
      {showControls && <ControlButtons />}
    </ButtonContext.Provider>
  );
};
