import React from "react";
import { useNavigate } from "react-router-dom";

// Assets
import { ReactComponent as CartIcon } from "@icons/cart.svg";
import { ReactComponent as RetailCoLogo } from "@icons/retail_co_logo.svg";
import { ReactComponent as PersonFilled } from "@icons/person_filled.svg";
import { ReactComponent as PersonOutline } from "@icons/person_outline.svg";
import { ReactComponent as OrdersIcon } from "@icons/order.svg";
import { ReactComponent as ReturnsIcon } from "@icons/return.svg";
import { ReactComponent as WalletIcon } from "@icons/wallet_lg.svg";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="h-screen overflow-hidden bg-grey">
      <div className="h-full mx-auto">
        <div className="h-full grid grid-cols-[303px,1fr] gap-2">
          <div className="flex flex-col gap-2 bg-white pl-3 pr-1 pt-3">
            <RetailCoLogo className="ml-3" />
            <div className="flex gap-x-3 py-9 px-3 bg-white">
              <div className="w-[92px] h-[92px] bg-trud-black3 rounded-full animate-pulse flex items-center justify-center">
                <PersonFilled />
              </div>
              <div className="flex flex-col justify-center ">
                <span className="text-body-black mt-2">Hi</span>
                <span className="text-blacked text-2xl">Consumer</span>
              </div>
            </div>

            <div className="bg-white flex-1 flex flex-col gap-2 font-inter font-medium text-sm">
              <div className="flex bg-stroke-primary items-center py-2 px-4 gap-x-3 text-sm opacity-50">
                <PersonOutline />
                <span className="">Profile</span>
              </div>
              <div className="flex  items-center py-2  px-4 gap-x-3 text-sm opacity-50">
                <OrdersIcon /> <span className="">Orders</span>
              </div>
              <div className="flex  items-center py-2 px-4 gap-x-3 text-sm opacity-50">
                <CartIcon className="" />
                <span className="">Shop</span>
              </div>

              <div className="flex hover:bg-stroke-primary  items-center py-2 px-4 gap-x-3 text-sm opacity-50">
                <ReturnsIcon />
                <span className="">Returns</span>
              </div>
              <div className="flex hover:bg-stroke-primary  items-center py-2 px-4 gap-x-3 text-sm opacity-50">
                <WalletIcon className="" />
                <span className="">Wallet</span>
              </div>
            </div>
          </div>
          <div className="bg-white pt-[20%] px-4">
            <div className="flex flex-col h-full items-center py-10">
              <span className="text-blacked mt-4">CREATE AN ACCOUNT</span>
              <div className="mt-6 h-3 w-1/2 bg-grey animate-pulse" />
              <div className="mt-4 h-3 w-1/3 bg-grey animate-pulse" />
              <button
                className="mt-6 bg-trud-black2 py-3 w-1/2 text-white cursor-pointer"
                onClick={() => navigate("/signup")}
              >
                CREATE AN ACCOUNT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
