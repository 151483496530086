import React from "react";

// Helpers
import { getClassNames } from "@utils/helpers";

import { ReactComponent as WarningIcon } from "@icons/warning.svg";

const IncorrectInputError = ({ show, message="This code is incorrect. Please try again." }) => {
  return (
    <div
      className={getClassNames(
        "bg-red-alert-100 rounded flex items-start gap-x-2",
        "w-full transition-all duration-200",
        show ? `first-line:border border-stroke mt-6 p-4` : "h-0"
      )}
    >
      {show ? (
        <>
          <WarningIcon className="min-w-[18px] self-start" />
          <span className="text-body-black leading-[120%]">
            {message}
          </span>
        </>
      ) : null}
    </div>
  );
};

export default IncorrectInputError;
