import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { authContext } from "@context/auth";

// Assets
import { ReactComponent as ShoesIcon } from "@icons/shoes.svg";
import { ReactComponent as BackIcon } from "@icons/back-arrow.svg";
import Sidebar from "./sidebar";
import TitleBar from "@components/TitleBar";

const Returns = () => {
  const navigate = useNavigate();
  const { addHistory } = useContext(authContext);
  const onReturn = async () => {
    addHistory(
      "Retail Co - Consumer Trust Index credential has been read",
      true
    );
    navigate("qr-scan");
  };

  return (
    <div className="bg-grey h-screen overflow-auto min-w-[1248px]">
      <div className="h-full mx-auto">
        <div className="h-full grid grid-cols-[303px,1fr] gap-2">
          <Sidebar />

          <div className="bg-white">
            <TitleBar title="Returns" />
            <div className="max-w-[1032px] mx-auto">
              <div className="flex items-center py-3 gap-[17px] border-b border-stroke">
                <div className="py-6 px-8 bg-stroke">
                  <ShoesIcon />
                </div>
                <div className="flex justify-between w-full">
                  <div className="flex flex-col gap-4">
                    <span className="text-blacked text-2xl whitespace-nowrap">
                      Suede Chelsea Boots
                    </span>
                    <span className="text-grey-content-secondary text-lg">
                      Black, Size 7
                    </span>
                  </div>

                  <div className="flex gap-8 items-center justify-around ">
                    <div className="flex gap-2 bg-stroke items-center justify-around px-3 py-2">
                      <span className="pr-2">1</span>
                      <BackIcon className="-rotate-90 cursor-pointer" />
                    </div>
                    <span>€85</span>
                  </div>
                </div>
              </div>
              <div className="relative group w-full max-w-[404px] mx-auto mt-9">
                <button
                  className="w-full max-w-[404px] bg-black py-3 px-8 rounded-full text-white cursor-pointer h-fit"
                  onClick={onReturn}
                >
                  Return
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Returns;
