import React, { useState } from "react";

// Helpers
import { getClassNames } from "@utils/helpers";

// Assets
import { ReactComponent as MaskIcon } from "@icons/mask.svg";

const labelStyleType = {
  regular: "text-desc-grey text-base leading-5 font-medium",
  auth: "text-grey text-lg leading-[1.375rem] font-medium",
};

const categoryType = {
  auth: getClassNames(
    "bg-grey-850 border border-stroke4 placeholder-placeholder-grey text-body-black text-base leading-6 md:text-lg",
    "md:leading-7 font-medium py-3 md:py-4 rounded px-2 mt-2 md:mt-2 w-full focus:outline-none focus:ring-0"
  ),
  regular: getClassNames(
    "border border-stroke outline-none bg-white4 placeholder-placeholder-grey text-body-black",
    "text-base -tracking-[0.4px] font-medium p-3 md:p-4 leading-5 rounded focus:border-b-desc-grey"
  ),
};

export const TextArea = ({
  name,
  defaultValue,
  placeholder,
  label,
  register,
  rules,
  errors,
  disabled,
  className,
  category = "regular",
  labelStyle = "",
}) => {
  const categoryType = {
    regular: getClassNames(
      "border border-stroke outline-none bg-white4 placeholder-placeholder-grey text-body-black",
      "text-base -tracking-[0.4px] font-medium p-3 md:p-4 leading-5 rounded focus:border-desc-grey w-full",
      className
    ),
  };

  return (
    <div className="w-full">
      {label && (
        <label
          htmlFor={name}
          className={getClassNames(
            labelStyle ? labelStyle : labelStyleType[category]
          )}
        >
          {label}
        </label>
      )}
      <textarea
        disabled={disabled}
        defaultValue={defaultValue}
        {...register(name, { ...rules })}
        className={getClassNames(categoryType[category])}
        placeholder={placeholder}
      />
      {errors && errors[name] && (
        <span className="text-red-alert text-sm">
          * {errors[name]?.message}
        </span>
      )}
    </div>
  );
};

const Input = ({
  name,
  type = "text",
  defaultValue,
  value,
  placeholder,
  label,
  register,
  rules,
  errors,
  disabled,
  className,
  category = "regular",
  labelStyle,
  ...props
}) => {
  const labelDefault = labelStyle ? "" : category;
  const [showPassword, setSetShowPassword] = useState(
    type === "password" ? false : true
  );

  return (
    <div className="w-full">
      {label && (
        <label
          htmlFor={name}
          className={getClassNames(labelStyleType[labelDefault], labelStyle)}
        >
          {label}
        </label>
      )}
      <div className="relative flex items-center">
        <input
          type={showPassword ? "text" : "password"}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          {...register(name, { ...rules })}
          className={getClassNames("w-full", categoryType[category], className)}
          placeholder={placeholder}
          {...props}
        />
        {type === "password" && (
          <MaskIcon
            className="absolute right-4 cursor-pointer"
            onClick={() => setSetShowPassword((prev) => !prev)}
          />
        )}
      </div>
      {errors && errors[name] && (
        <span className="text-red-alert text-sm">
          * {errors[name]?.message}
        </span>
      )}
    </div>
  );
};

export default Input;

export const InputContainer = ({
  label,
  labelStyle,
  category = "regular",
  className,
  children,
}) => {
  const labelDefault = labelStyle ? "" : category;
  return (
    <div>
      {label && (
        <span
          className={getClassNames(labelStyleType[labelDefault], labelStyle)}
        >
          {label}
        </span>
      )}
      <div className={getClassNames(categoryType[category], className)}>
        {children}
      </div>
    </div>
  );
};
