import React from "react";

// Utils
import {
  getClassNames,
  // getScoreColor,
  getRiskColor,
  getRiskTitle,
  getScoreColorWithRisk,
} from "@utils/helpers";
import ToolTip from "@components/ToolTip";

export const ScoreBubble = ({ score, risk_level, inactive }) => (
  <div
    className={getClassNames(
      "py-4 px-6 bg-grey text-[3.5rem] flex items-center justify-center",
      "min-w-[6rem] min-h-[6rem] max-w-[6rem] max-h-[6rem] rounded-full",
      "text-black",
      getScoreColorWithRisk(risk_level)
    )}
  >
    {score}
  </div>
);
export const ScoreBubbleMid = ({ score, risk }) => {
  return (
    <div
      className={getClassNames(
        " bg-grey text-[2.625rem] flex items-center justify-center",
        "min-w-[4.625rem] min-h-[4.625rem] max-w-[4.625rem] max-h-[4.625rem] rounded-full",
        "text-black",
        getScoreColorWithRisk(risk)
      )}
    >
      {score}
    </div>
  );
};

export const ScoreBubbleSmall = ({ score, risk_level }) => (
  <div
    className={getClassNames(
      " bg-grey text-xl flex items-center justify-center",
      "min-w-[2.625rem] min-h-[2.625rem] max-w-[2.625rem] max-h-[2.625rem] rounded-full",
      "text-black",
      getScoreColorWithRisk(risk_level)
    )}
  >
    {score}
  </div>
);

export const RiskTag = ({
  title,
  risk,
  className = "bg-grey-10 px-1 rounded-sm border border-stroke-secondary",
  inactive = false,
  showInfo = false,
}) => {
  return (
    <div
      className={getClassNames(
        "relative group flex items-center gap-x-1",
        className
      )}
    >
      <div
        className={getClassNames(
          "w-3 h-3 rounded-sm",
          getRiskColor(risk, inactive)
        )}
      />
      <span className="text-grey-content-secondary">{getRiskTitle(risk)}</span>
      {showInfo && (
        <ToolTip
          info="Eligible for instant refunds"
          className="top-6 right-0 min-w-[193px]"
        />
      )}
    </div>
  );
};
