import React from "react";
import { useNavigate } from "react-router-dom";

// Utils
import { getClassNames } from "@utils/helpers";

// Assets
import { ReactComponent as BackIcon } from "@icons/back-arrow.svg";

const BackArrow = ({ onClick, className }) => {
  const navigate = useNavigate();
  return (
    <div
      className={getClassNames(
        "bg-grey-stroke-primary rounded-s-lg w-7 h-[26px] flex items-center justify-center cursor-pointer",
        className
      )}
      onClick={onClick ? onClick : () => navigate(-1)}
    >
      <BackIcon />
    </div>
  );
};

export default BackArrow;
