import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as RetailCoLogo } from "@icons/retail_co_logo.svg";
import ShopperPerson from "@images/shopper-person.png";
import { Button } from "@components/button";

const EndDemo = () => {
  const navigate = useNavigate();
  const switchProfile = () => {
    localStorage.removeItem("shopperData");
    navigate("/");
  };

  return (
    <div className="h-screen overflow-auto relative">
      <div className="-z-10 h-full mx-auto bg-[rgba(51,51,51,0.30)] blur-sm absolute inset-0">
        <div className="max-w-[1230px] mx-auto py-6">
          <RetailCoLogo className="" />
        </div>
      </div>
      <div className="z-20 flex items-center justify-center h-screen">
        <div className="rounded-2xl overflow-hidden bg-white max-w-[528px]">
          <img src={ShopperPerson} alt="Consumer Person" />
          <div className="py-8 px-10 bg-white">
            <h3 className="header4">
              You’ve reached the end of this shopping experience
            </h3>
            <p className="paragraph1 mt-2 text-grey-content-secondary">
              To experience personalised payments for a medium or high risk
              consumer, switch the consumer profile. Or visit the Merchant Demo
              to customise the consumer fraud risk algorithm.
            </p>
            <div className="flex gap-2 items-center mt-6">
              <Button
                title="Switch Consumer Profile"
                color="dark"
                className=""
                onClick={switchProfile}
              />
              <a href="http://experience.merchantiec.trudenty.com/use-case?arg=payment_personalisation">
                <Button title="Go to Console Demo" color="light" className="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndDemo;
