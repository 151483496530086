import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// Context
import { authContext } from "@context/auth";

// Utils
import { getInitials } from "@utils/helpers";

// Components
import Sidebar from "./sidebar";

// Assets
import { ReactComponent as GuideIcon } from "@assets/icons/help_icon.svg";
import TitleBar from "@components/TitleBar";
import { useSelector } from "react-redux";
import { isRegisteredSelector } from "../../redux/slice/registeredSlice.js";
import Wallet from "./wallet";
import { useForm } from "react-hook-form";
import Input from "@components/input";
import { Button } from "@components/button";
import consumerProfile from "@images/web-demo.gif.png";

const Profile = () => {
  const navigate = useNavigate();
  const isRegistered = useSelector(isRegisteredSelector);
  const { shopperData } = useContext(authContext);
  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { fullname } = shopperData || { fullname: "" };

  return (
    <>
      {isRegistered ? (
        <Wallet />
      ) : (
        <div className="h-screen bg-grey">
          <div className="h-full mx-auto">
            <div className="h-full grid grid-cols-[303px,1fr] gap-2">
              <Sidebar />
              <div className="bg-white px-4">
                <TitleBar title="Profile" />
                <div className="max-w-[1032px] mx-auto">
                  <div className="flex items-center gap-x-4 mt-12 border-b border-stroke">
                    <div className="min-w-[75px] min-h-[75px] bg-trud-black3 rounded-full text-white flex text-[2rem] items-center justify-center">
                      {getInitials(fullname)}
                    </div>
                    <div className="p-6 flex bg-grey-650 border border-stroke-primary rounded-lg mb-8 gap-3 mt-6 text-grey-content-secondary ml-5">
                      <GuideIcon />
                      <span className="text-lg">
                        This profile page serves as a mockup of what your
                        consumers will see in your application. We
                        <br /> recommend embedding the initialisation button
                        here and highlighting any benefits or incentives for
                        <br /> the consumers.
                      </span>
                    </div>
                  </div>
                  <div className="grid grid-cols-12 gap-5 px-4 py-10 font-medium">
                    <div className=" col-span-12 xl:col-span-6">
                      <Input
                        label="First name"
                        type="text"
                        value={shopperData?.fullname?.split(" ")[0]}
                        name="firstName"
                        placeholder="Enter First Name"
                        className="rounded-lg bg-white border border-grey-content-quaternary text-body-black"
                        isDisabled={true}
                        {...{ register, errors }}
                      />
                    </div>
                    <div className=" col-span-12 xl:col-span-6">
                      <Input
                        label="Last name"
                        type="text"
                        value={shopperData?.fullname?.split(" ")[1]}
                        name="lastName"
                        placeholder="Enter Last Name"
                        className="rounded-lg bg-white border border-grey-content-quaternary text-body-black"
                        isDisabled={true}
                        {...{ register, errors }}
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Input
                        label="Email"
                        type="text"
                        value={localStorage.getItem("email")}
                        name="email"
                        placeholder="Enter Email"
                        className="rounded-lg bg-white border border-grey-content-quaternary text-body-black"
                        isDisabled={true}
                        {...{ register, errors }}
                      />
                    </div>
                    <div className="col-span-12 md:col-span-6">
                      <Input
                        label="Phone number"
                        type="text"
                        value={localStorage.getItem("phone_no")}
                        name="phoneNumber"
                        className="rounded-lg bg-white border border-grey-content-quaternary text-body-black"
                        isDisabled={true}
                        {...{ register, errors }}
                      />
                    </div>
                    <div className="col-span-12">
                      <Input
                        label="Loyalty ID"
                        type="text"
                        value={shopperData?.project_id}
                        name="loyaltyId"
                        className="rounded-lg bg-white border border-grey-content-quaternary text-body-black"
                        isDisabled={true}
                        {...{ register, errors }}
                      />
                    </div>
                  </div>
                  <div
                    className={`mb-16 footer-add rounded-lg flex  gap-4  overflow-hidden border border-grey-stroke-primary max-w-[100%-16px] mx-4`}
                  >
                    <img
                      src={consumerProfile}
                      className=" min-w-36 min-h-20 object-cover"
                      alt=""
                    />
                    <div className=" flex md:flex-row flex-col items-center gap-3 p-4">
                      <div>
                        <h4 className="font-medium text-black text-base mb-1">
                          Get your Consumer Trust Index
                        </h4>
                        <p className="text-grey-content-secondary font-medium text-[15px]">
                          Enjoy personalised experiences tailored to you like
                          instant refunds and payment upon delivery. To provide
                          you with these amazing benefits, we need to calculate
                          your Consumer Trust Index. We do this through a simple
                          3-step open banking process with your bank.
                        </p>
                      </div>
                      <div>
                        <Button
                          title={"Get started"}
                          category="3"
                          color="text-white"
                          type="button"
                          className="rounded-[4rem]  px-5 py-3 text-base text-left w-auto inline-block font-medium  bg-btn-primary text-white"
                          onClick={() => {
                            navigate("/setup");
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Profile;
