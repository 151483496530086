import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

// Assets
import identity_access_popup from '@images/identity_access_popup.png';
import { ReactComponent as CheckIcon } from '@icons/check-success.svg';
import { Button } from '@components/button';

const PasskeyCreateModal = ({ showConsent, setShowConsent }) => {
  const navigate = useNavigate();
  const [created, setCreated] = useState(false);
  return (
    <Transition appear show={showConsent} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full mt-40 justify-center text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel>
                {created ? (
                  <div className='bg-white h-fit min-w-[361px] rounded-2xl p-6 pb-2 flex flex-col'>
                    <div className='flex flex-1'>
                      <div>
                        <div className='flex items-center gap-x-2'>
                          <CheckIcon />
                          <h3 className='text-2xl text-content-primary'>
                            Account created
                          </h3>
                        </div>
                        <p className='text-desc-grey max-w-[222px] text-left'>
                          You will now be securely authenticated with your
                          Passkey
                        </p>
                      </div>
                      {/* <PasskeyIllustration className="max-h-[89px] max-w-[84px]" /> */}
                    </div>
                    <Button
                      title='Log in'
                      color='dark'
                      className='w-full mt-10'
                      onClick={() => {
                        setShowConsent(false);
                        navigate('/login');
                      }}
                    />
                  </div>
                ) : (
                  <img
                    src={identity_access_popup}
                    alt=''
                    className='max-w-[248px]'
                    onClick={() => setCreated(true)}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PasskeyCreateModal;
