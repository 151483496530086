import React from "react";
import { useForm } from "react-hook-form";

// Utils
import { getClassNames } from "@utils/helpers";

// Components
import { Button } from "@components/button";
import Input from "@components/input";

// Assets
import { useLocation, useNavigate } from "react-router-dom";

const LoginBank = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async () => {
    if (location?.state?.from === "authenticate") {
      navigate("/consent-authorization", { state: { ...location?.state } });
    } else {
      navigate("/payment-authorization", { state: { ...location?.state } });
    }
  };

  return (
    <div className="bg-[#4aa0b5]">
      <div className="overflow-scroll">
        <div className="flex flex-col items-center justify-center h-screen max-w-[1440px] mx-auto">
          <div
            className={getClassNames(
              "w-full py-16 mx-auto container-auth",
              "flex flex-col rounded items-center"
            )}
          >
            <div
              className={getClassNames(
                "w-full max-w-[832px] mx-auto mb-10",
                "flex flex-col items-center"
              )}
            >
              <div className="w-full max-w-[555px] h-full ">
                <div className="bg-grey rounded-lg p-8 w-full">
                  <h3 className="text-[22px] mb-8 text-center text-[#4aa0b5]">
                    LOGIN
                  </h3>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex-1 w-full h-full"
                  >
                    <div className="flex flex-col gap-y-4 w-full">
                      <Input
                        name={"username"}
                        label={"Username:"}
                        category="auth"
                        defaultValue={localStorage.getItem("email")}
                        {...{ register, errors }}
                        rules={{
                          required: true,
                        }}
                        labelStyle="text-[#4aa0b5]"
                        placeholder="Username *"
                      />
                      <Input
                        name={"password"}
                        type={"password"}
                        label={"Password:"}
                        category="auth"
                        defaultValue="123456"
                        {...{ register, errors }}
                        rules={{
                          required: true,
                        }}
                        labelStyle="text-[#4aa0b5]"
                        placeholder="Your Password *"
                      />
                    </div>

                    <Button
                      title="Submit"
                      color=""
                      size="custom"
                      className="mt-14 w-fit text-white bg-[#4aa0b5] rounded-md"
                      // disabled={!isValid}
                      type="submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginBank;
