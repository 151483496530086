import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// Utils
import { getClassNames } from '@utils/helpers';
import { authContext } from '@context/auth';

// Components

// Assets
import { ReactComponent as RetailCoLogo } from '@icons/retail_co_logo.svg';
import { ReactComponent as PackageIcon } from '@icons/package.svg';

const OrderConfirmationAfter = () => {
  const navigate = useNavigate();
  const user = localStorage.getItem('user');
  if (!user) return navigate('/login');
  const firstName = JSON.parse(user)?.FirstName;
  return (
    <div className='flex flex-col h-full bg-white w-full mx-auto max-w-[695px] px-10'>
      <div className={getClassNames('bg-[#22222205] pt-3')}>
        <div className='container mx-auto min-h-[52px] relative'>
          <RetailCoLogo className='' />
        </div>
      </div>
      <div className='h-full overflow-auto'>
        <div
          className={getClassNames(
            'h-full w-full py-16 mx-auto container-auth',
            'flex-1 flex flex-col h-full rounded items-center',
          )}
        >
          <div
            className={getClassNames(
              'w-full max-w-[832px] mx-auto flex-1 mb-10',
              'flex flex-col items-center',
            )}
          >
            <div className='flex-1 px-14 py-5 rounded-lg bg-grey-810 w-full min-h-[254px]'>
              <h4 className='header4'>Thanks {firstName},</h4>
              <p className='mt-4 text-base1 text-grey-content-secondary'>
                Your payment has been received, enjoy!
              </p>
            </div>

            <div className='mt-8 py-3 w-full'>
              <h4 className='header4'>You ordered this</h4>
              <div className='flex items-center mt-7'>
                <div className='py-6 px-8 bg-stroke'>
                  <PackageIcon />
                </div>
                <div className='w-1/3 ml-8 max-w-[167px]'>
                  <div className='h-3 w-full bg-stroke animate-pulse' />
                  <div className='h-3 mt-2 w-2/3 bg-stroke animate-pulse' />
                </div>
              </div>
            </div>

            <div className='flex justify-center w-full mt-8'>
              <button
                className='w-full max-w-[404px] bg-body-black py-3 px-8 text-white cursor-pointer h-fit'
                onClick={() => navigate('/dashboard/1/wallet')}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmationAfter;
