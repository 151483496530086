import React, { useContext } from "react";

// Utils
import { getClassNames } from "@utils/helpers";

// Context
import { authContext } from "@context/auth";

// Assets
import { ReactComponent as RetailCoLogo } from "@icons/retail_co_table_logo.svg";
import LastSeen from "@components/LastSeen";

const HistoryTable = () => {
  const { historyData } = useContext(authContext);
  return (
    <div className="border border-grey-10 rounded">
      {historyData.map(({ body, action, sub, time, type }, i) => (
        <div className="group grid grid-cols-[64px,2fr,4fr,2fr,1fr] hover:bg-white2 items-center">
          <div className="counter text-inactive-grey text-sm self-center w-full mx-auto flex items-center justify-center border-b border-grey-10 h-full">
            {i + 1}
          </div>
          <div
            className={getClassNames(
              "title text-body-black text-base pl-6 flex items-center gap-x-3 self-center",
              "h-full border-b border-l py-6 border-grey-10 cursor-pointer"
            )}
          >
            <RetailCoLogo />
          </div>
          <div className="h-full border-b border-l py-6 border-grey-10 pl-6">
            <p className="text-grey-content-tertiary text-sm1 leading-base1 max-w-[288px] h-full">
              {body} <span className="text-grey-content-secondary">{action}</span> {sub}
            </p>
          </div>
          <div className="h-full border-b border-l py-6 border-grey-10 pl-6">
            <p className="text-grey-content-tertiary text-sm1 leading-base1 max-w-[380px] h-full flex items-center">
              {<LastSeen date={time} />}
            </p>
          </div>
          <div className="h-full flex items-center border-b border-l py-6 border-grey-10 pl-6">
            <p
              className={getClassNames(
                "text-sm1 leading-base1 flex items-center capitalize",
                "border border-stroke px-2 py-1 rounded bg-white",
                type === 0 ? "text-red-alert" : "text-body-black"
              )}
            >
              {type === 0 ? "Revoke" : "View"}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HistoryTable;
