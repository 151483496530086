import React, { useContext } from "react";

// Context
import { authContext } from "@context/auth";

// Components
import HistoryTable from "./historytable";
import {
  ShopperContactCard,
  ShopperTrustIndexInfoCard,
} from "@pages/dashboard/components/shopperDetailsCard";

// Components
import Sidebar from "./sidebar";
import TitleBar from "@components/TitleBar";

const Wallet = () => {
  const { shopperData, shopperScore } = useContext(authContext);

  return (
    <div className="bg-grey h-screen overflow-auto">
      <div className="h-full mx-auto">
        <div className="h-full grid grid-cols-[303px,1fr] gap-2">
          <Sidebar />

          <div className="bg-white">
            <TitleBar title="Profile" />
            <div className="max-w-[1032px] mx-auto">
              <h5 className="text-body-black">Credentials</h5>
              <div className="grid grid-cols-2 gap-x-4">
                <ShopperTrustIndexInfoCard shopperData={shopperData} />
                <ShopperContactCard shopperData={shopperData} />
              </div>
              <h4 className="text-lg text-body-black mt-16">History</h4>
              <HistoryTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
