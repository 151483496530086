import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";

// Utils
import { getClassNames } from "@utils/helpers";

// Hooks
import useScreenSize from "@hooks/useScreenSize";

// Components
import { Button } from "@components/button";
import CaretSm from "@icons/CaretSm";
import { ScoreBubble } from "./shoppersCharts";

import BarChart from "./Barchart";

const ShopperDetails = ({ shopper }) => {
  const score = shopper?.attributes?.reduce((accumulator, currentValue) => {
    return accumulator + parseInt(currentValue?.score);
  }, 0);
  const monthsList = ["SEP", "AUG", "JUL", "JUN", "MAY", "APR"];

  const { isMobile } = useScreenSize();
  const [index, setIndex] = useState(0);

  return (
    <div className="bg-white">
      <div className="relative bg-white2 h-[179px] md:h-auto py-2 md:py-6 pb-0 md:pb-0 px-3 md:px-8 flex flex-col md:flex-row md:items-center justify-between">
        <div className="absolute right-2 top-2 md:hidden flex gap-1 ml-auto w-fit">
          <div
            className={getClassNames(
              "h-0.5 w-6 rounded-lg",
              index === 0 ? "bg-grey-content-secondary" : "bg-stroke-secondary"
            )}
          />
          <div
            className={getClassNames(
              "h-0.5 w-6 rounded-lg",
              index === 1 ? "bg-grey-content-secondary" : "bg-stroke-secondary"
            )}
          />
        </div>
        {isMobile ? (
          <SwipeableViews
            enableMouseEvents
            className="overflow-hidden"
            onChangeIndex={(index) => setIndex(index)}
          >
            <div className="flex flex-col max-w-max mt-4 md:mt-0">
              <ScoreBubble {...{ score }} risk_level={shopper?.risk_level} />
              {/* {score && <RiskTag
                risk={getRiskWithScore(score)}
                title={getRiskTitle(getRiskWithScore(score))}
                className="mt-2 border border-stroke rounded-sm px-2"
              />} */}
            </div>
            <div className="relative flex-1 max-w-[400px]">
              <div className="flex  justify-between left-0 right-0">
                <span className="text-grey-content-tertiary">
                  Score History
                </span>
                <Button
                  title="2024"
                  className="hidden md:inline-block pt-[2px] md:-mt-1 pb-[2px] px-[6px] text-sm1 text-body-black"
                  suffixIcon={
                    <CaretSm color="#747678" className="ml-1 rotate-180" />
                  }
                />
              </div>
              <BarChart
                height={175}
                series={[
                  {
                    name: "series-1",
                    data: [score, 0, 0, 0, 0, 0],
                  },
                ]}
                chartOptions={{
                  xaxis: {
                    categories: monthsList,
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                  },
                }}
                columnWidth={
                  monthsList?.length == 1
                    ? "30%"
                    : monthsList?.length == 2
                    ? "60%"
                    : "90%"
                }
              />
            </div>
          </SwipeableViews>
        ) : (
          <VisualComponent
            {...{ score }}
            risk_level={shopper?.risk_level}
            months={monthsList}
          />
        )}
      </div>
      {shopper?.attributes?.map((attr, i) => (
        <div
          className={getClassNames(
            "grid grid-cols-[1fr,90px] md:grid-cols-[45px,1fr,200px]  divide-x-[1px] hover:bg-white2 divide-grey-850 border-b border-grey-10",
            attr.score === 0 && "opacity-60"
          )}
          key={attr.name}
        >
          <div className="hidden md:inline-block text-sm text-grey-content-secondary text-center py-6">
            {i + 1}
          </div>
          <div className="py-4 md:py-6 px-4">
            <span className="md:text-lg text-body-black">{attr.name}</span>
            <p className="mt-1 md:mt-2 text-grey-content-tertiary">
              {attr.description}
            </p>
          </div>
          <div className="flex flex-col gap-y-1 items-center justify-center py-6">
            <span
              className={getClassNames(
                "rounded px-2 border-grey-stroke-primary text-sm md:text-sm1 bg-stroke w-8 h-8 md:w-[42px] md:h-[42px]",
                "flex items-center justify-center"
              )}
            >
              {attr?.score === 0 ? " -" : parseInt(attr?.score)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ShopperDetails;

export const VisualComponent = ({ score, risk_level, inactive, months }) => (
  <>
    <div className="flex flex-col max-w-max">
      <ScoreBubble {...{ score, risk_level, inactive }} />
      {/* {score && <RiskTag
        risk={getRiskWithScore(score)}
        title={getRiskTitle(getRiskWithScore(score))}
        inactive={inactive}
        className="mt-2 border border-stroke rounded-sm px-2"
      />} */}
    </div>
    <div className="relative flex-1 max-w-[400px] mt-10 md:mt-0">
      <div className="flex  justify-between left-0 right-0">
        <span className="text-grey-content-tertiary">Score History</span>
        <Button
          title="2024"
          className="pt-[2px] -mt-1 pb-[2px] px-[6px] text-sm1 text-body-black"
          suffixIcon={<CaretSm color="#747678" className="ml-1 rotate-180" />}
        />
      </div>
      <BarChart
        height={175}
        series={[
          {
            name: "series-1",
            data: [score, 0, 0, 0, 0, 0],
          },
        ]}
        chartOptions={{
          xaxis: {
            categories: months,
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
        }}
        columnWidth={
          months?.length == 1 ? "30%" : months?.length == 2 ? "60%" : "90%"
        }
      />
    </div>
  </>
);
