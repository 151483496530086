import { createContext, useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Utils
import { axiosInstance } from "@utils/baseAxios";

// Context
import { authContext } from "./auth";
export const cookieContext = createContext();

const CookieContextProvider = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setHasCookie } = useContext(authContext);

  useEffect(() => {
    const checkSession = async () => {
      if (
        location.pathname.startsWith("/protect")
      ) {
        return;
      }

      await axiosInstance
        .get("/check_session")
        .then((data) => {
          const email = localStorage.getItem("email");
          data.data.result.email &&
            !email &&
            localStorage.setItem("email", data.data.result.email);
          setHasCookie(true);
        })
        .catch((_error) => {
          setHasCookie(false);
          navigate("/protect/login")
        });
    };
    checkSession();
  }, [location.pathname, navigate, setHasCookie]);

  return (
    <cookieContext.Provider value={{}}>{props.children}</cookieContext.Provider>
  );
};

export default CookieContextProvider;
